import * as React from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { difference } from 'ramda'
import { useGetGeoInfoQuery } from 'services'
import fetchAssetData from 'thunks/fetchAssetData'
import fetchPortfolioData from 'thunks/fetchPortfolioData'
import fetchSubportfolioData from 'thunks/fetchSubportfolioData'
import store, { RootState } from 'store'
import useLatlongs from 'hooks/useLatlongs'
import usePortfolios from 'hooks/usePortfolios'
import useFocusAddress from 'hooks/useFocusAddress'
import { concat, uniq } from 'lodash'

import useSubportfolios from 'hooks/useSubportfolios'

const getNutsLevel = (sectionName: string) => {
  if (sectionName === 'country_scores') {
    return 0
  }
  return 3
}

const mergeFilterKeys = (
  obj1: Record<string, Record<string, boolean>>,
  obj2: Record<string, Record<string, boolean>>
) => {
  const keys = uniq(concat(Object.keys(obj1), Object.keys(obj2)))

  const result: Record<string, Record<string, boolean>> = {}
  keys.forEach((key) => {
    result[key] = {
      ...obj1[key],
      ...obj2[key],
    }
  })

  return result
}

const AssetDataService: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const latlongs = useLatlongs()
  const focus = useFocusAddress()
  const latLongsWithFocus = React.useMemo(
    () => (focus ? [...latlongs, focus] : latlongs),
    [latlongs, focus]
  )

  const { data } = useGetGeoInfoQuery(latLongsWithFocus, {
    skip: latLongsWithFocus.length === 0,
  })
  const filters = useSelector((state: RootState) => state.filter.filters || {})
  const portfolios = usePortfolios()
  const subportfolios = useSubportfolios()
  const clusterKeys = useSelector((state: RootState) => state.clusterView)

  const filtersWithClusterKeys = React.useMemo(() => {
    return mergeFilterKeys(filters, clusterKeys)
  }, [filters, clusterKeys])

  React.useEffect(() => {
    const state = store.getState()

    Object.entries(filtersWithClusterKeys).forEach(
      ([sectionName, filterObject]) => {
        const nutsLevel = getNutsLevel(sectionName)

        batch(() => {
          latLongsWithFocus.forEach(({ lat, long }) => {
            const geocode = data?.[`${lat};${long}`]?.[nutsLevel]?.id

            if (geocode) {
              const assetData =
                geocode &&
                state.assetData.address?.[`${lat};${long}`]?.[sectionName]
              const currentFilters = Object.keys(assetData || {})

              const newFilters = difference(
                Object.keys(filterObject || {}),
                currentFilters
              )

              dispatch(
                fetchAssetData(
                  sectionName,
                  `${lat};${long}`,
                  geocode,
                  newFilters
                )
              )
            }
          })
        })
      }
    )
  }, [filtersWithClusterKeys, data, dispatch, latLongsWithFocus])

  React.useEffect(() => {
    const state = store.getState()

    Object.entries(filtersWithClusterKeys).forEach(
      ([sectionName, filterObject]) => {
        batch(() => {
          portfolios.forEach(({ id }) => {
            const assetData = state.assetData.portfolio?.[id]?.[sectionName]
            const currentFilters = Object.keys(assetData || {})
            const newFilters = difference(
              Object.keys(filterObject || {}),
              currentFilters
            )

            dispatch(fetchPortfolioData(String(id), sectionName, newFilters))
          })
        })
      }
    )
  }, [portfolios, focus, filtersWithClusterKeys, dispatch])

  React.useEffect(() => {
    const state = store.getState()

    Object.entries(filtersWithClusterKeys).forEach(
      ([sectionName, filterObject]) => {
        batch(() => {
          subportfolios.forEach(({ id }) => {
            const assetData = state.assetData.subportfolio?.[id]?.[sectionName]
            const currentFilters = Object.keys(assetData || {})
            const newFilters = difference(
              Object.keys(filterObject || {}),
              currentFilters
            )

            dispatch(fetchSubportfolioData(String(id), sectionName, newFilters))
          })
        })
      }
    )
  }, [subportfolios, focus, filtersWithClusterKeys, dispatch])

  return <>{children}</>
}

export default AssetDataService
