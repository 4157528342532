import { getToken } from 'utils/auth'
import { useSelector } from 'react-redux'
import { encodeAddress } from 'utils/url'
import { RootState } from 'store'
import React from 'react'

export const API_BASE_URL = process.env.REACT_APP_API_URL || window.location.origin

export class StatusError extends Error {
  status: number

  constructor(status: number, message: string) {
    super(message)
    this.message = message
    this.status = status
  }

  formattedString = (): string => `Status: ${this.status} - ${this.message}`

  static isStatusError(e: Error): boolean {
    return e instanceof StatusError
  }
}

export const fetchJson = async (
  url: string,
  ops?: Record<string, unknown>
): Promise<Record<string, unknown>> => {
  const r = await fetch(url, ops)

  try {
    const j = await r.json()
    if (r.ok) {
      return j
    }
    throw new StatusError(
      r.status,
      j.message
        ? `${j.status_label} - '${j.message}'`
        : `Error calling '${url}'.`
    )
  } catch {
    // try again to parse body as json
    try {
      const j = await r.json()
      throw new StatusError(
        r.status,
        j.message
          ? `${j.status_label} - '${j.message}'`
          : `Error calling '${url}'.`
      )
    } catch (e) {
      // backoff to generic error
      throw new StatusError(r.status, `Error calling '${url}'.`)
    }
  }
}

export const fetchAssetData = async (
  sectionName: string,
  geocode: string,
  filters: string[]
): Promise<Record<string, number> | null> => {
  try {
    const filterQuery = filters
      .map((filter) => {
        return `key=${encodeURIComponent(filter)}`
      })
      .join('&')
    const tk = getToken()

    const url = `${API_BASE_URL}/data/${sectionName}/${geocode}?${filterQuery}&tk=${tk}`
    return (await fetchJson(url)) as Record<string, number>
  } catch (e) {
    return null
  }
}

export const exportData = () => {
  const tk = getToken()
  const exportUrl = `${API_BASE_URL}/data/export${window.location.pathname.replace(
    '/data-view',
    ''
  )}${window.location.search}&tk=${tk}`

  window.location.assign(exportUrl)
}

export const useExportData = () => {
  const portfolios = useSelector(
    (state: RootState) => state.assetView.portfolio
  )
  const subportfolios = useSelector(
    (state: RootState) => state.assetView.subportfolio
  )
  const addresses = useSelector((state: RootState) => state.assetView.address)

  const portfolioPart = React.useMemo(
    () =>
      Object.values(portfolios)
        .map(({ id, name }) => `portfolio=${id};${name}`)
        .join('&'),
    [portfolios]
  )
  const subportfolioPart = React.useMemo(
    () =>
      Object.values(subportfolios)
        .map(({ id, name }) => `subportfolio=${id};${name}`)
        .join('&'),
    [subportfolios]
  )
  const addressPart = React.useMemo(
    () =>
      Object.values(addresses)
        .map((address) => `latlong=${encodeAddress(address)}`)
        .join('&'),
    [addresses]
  )

  return React.useCallback(() => {
    const tk = getToken()
    const exportUrl = `${API_BASE_URL}/data/export${window.location.pathname.replace(
      '/data-view',
      ''
    )}${
      window.location.search
    }&${portfolioPart}&${subportfolioPart}&${addressPart}&tk=${tk}`

    window.location.assign(exportUrl)
  }, [portfolioPart, subportfolioPart, addressPart])
}
