type Latlong = {
  lat: string
  long: string
  address: string
  assetType: string
}

export const decodeAddress = (encodedAddress: string) => {
  const splitted = encodedAddress.split(';')

  return {
    lat: splitted[0],
    long: splitted[1],
    address: splitted[2],
    assetType: splitted[3],
  }
}

export const decodePortfolio = (encodedPortfolio: string) => {
  const splitted = encodedPortfolio.split(';')

  return {
    name: splitted[1],
    id: splitted[0],
  }
}

export const decodeSubportfolio = (encodedSubportfolio: string) => {
  const splitted = encodedSubportfolio.split(';')

  return {
    parentFundId: splitted[2],
    name: splitted[1],
    id: splitted[0],
  }
}

export const encodeAddress = ({ lat, long, address, assetType }: Latlong) =>
  `${lat};${long};${address};${assetType}`

export const appendCurrentSearchParams = (
  url: string,
  extraParams?: string
) => {
  const { searchParams } = new URL(document.location.href)

  if (searchParams && extraParams) {
    return `${url}?${searchParams}&${extraParams}`
  } else if (searchParams) {
    return `${url}?${searchParams}`
  } else if (extraParams) {
    return `${url}?${extraParams}`
  }

  return url
}
