import * as React from 'react'
import type { Column } from 'hooks/useOrderedColumns'
import { ColumnVariant } from 'hooks/useOrderedColumns'

const useHeaderTitle = (
  columns: Column[],
  {
    openDeleteFocusDialog,
    openDeleteAddressDialog,
    openDeletePortfolioDialog,
    openDeleteSubportfolioDialog,
  }: {
    openDeleteFocusDialog?: () => void
    openDeleteAddressDialog?: (
      id: string,
      lat: string,
      long: string,
      address: string
    ) => void
    openDeletePortfolioDialog?: (
      portfolioId: string,
      portfolioName: any
    ) => void
    openDeleteSubportfolioDialog?: (
      subportfolioId: string,
      subportfolioName: any
    ) => void
  }
): { title: string; deleteAction?: () => void; subtitles?: string[] }[] =>
  React.useMemo(
    () =>
      columns.map(({ data, variant }) => {
        switch (variant) {
          case ColumnVariant.Address:
            return {
              title: data.address,
              subtitles: [data.assetType],
              deleteAction:
                openDeleteAddressDialog &&
                (() =>
                  openDeleteAddressDialog(
                    data.id,
                    data.lat,
                    data.long,
                    data.address
                  )),
            }
          case ColumnVariant.Focus:
            return {
              title: data.address,
              deleteAction: openDeleteFocusDialog,
              subtitles: [data.assetType],
            }
          case ColumnVariant.Portfolio:
            return {
              title: data.name,
              subtitles: [data.reitStatus, data.sector, data.country],
              deleteAction:
                openDeletePortfolioDialog &&
                (() => openDeletePortfolioDialog(data.id, data.name)),
            }
          case ColumnVariant.Subportfolio:
            return {
              title: data.name,
              subtitles: [data.reitStatus, data.sector, data.country],
              deleteAction:
                openDeleteSubportfolioDialog &&
                (() => openDeleteSubportfolioDialog(data.id, data.name)),
            }
        }
      }),
    [
      columns,
      openDeleteFocusDialog,
      openDeleteAddressDialog,
      openDeletePortfolioDialog,
      openDeleteSubportfolioDialog,
    ]
  )

export default useHeaderTitle
