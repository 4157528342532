import * as React from 'react'
import { Box, DialogContent, Typography } from '@mui/material'
import { useGetAssetTypesQuery } from 'services'
import { useGoogleMapsService } from 'contexts/GoogleMapsService'
import { DialogActions } from '@mui/material'
import AddressSearch from 'components/AddressSearch'
import DropDown from 'UI/DropDown'
import CommonButton from 'UI/CommonButton'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import type { SelectChangeEvent } from '@mui/material'
import { addAddress } from 'reducers/assetView'
import { useDispatch } from 'react-redux'
import type { TPlace } from 'types'

const dialogActionsStyle = {
  display: 'flex',
  justifyContent: 'row',
  padding: 2,
}

const AddDialog = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const dispatch = useDispatch()
  const { getGeocode } = useGoogleMapsService()
  const [address, setAddress] = React.useState<TPlace>()
  const { data } = useGetAssetTypesQuery()
  const [assetType, setAssetType] = React.useState<string>('')

  const assetTypeMenuOptions = React.useMemo(
    () => [
      ...(data || []).map((value) => ({ value, label: value })),
      { value: '', label: 'Nothing selected' },
    ],
    [data]
  )

  const handleSubmit = React.useCallback(() => {
    if (getGeocode && address && assetType) {
      getGeocode(address.place_id).then(([geo]: any) => {
        const lat = geo.geometry.location.lat()
        const long = geo.geometry.location.lng()

        dispatch(
          addAddress({
            id: geo.place_id,
            lat,
            long,
            address: geo.formatted_address,
            assetType,
          })
        )
      })
    }
    onClose()
  }, [address, assetType, getGeocode, onClose, dispatch])

  const dropDownSelectProps = React.useMemo(
    () => ({
      value: assetType,
      onChange: (e: SelectChangeEvent<unknown>) =>
        setAssetType(e.target.value as string),
    }),
    [assetType]
  )

  return (
    <Dialog maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: 'bold' }}>Add address</DialogTitle>
      <DialogContent>
        <Typography>
          Choose an address and select the corresponding asset type to add it as
          a comparison.
        </Typography>
        <Box paddingBottom={2} paddingTop={4}>
          <AddressSearch value={address} setValue={setAddress} />
        </Box>
        <DropDown
          options={assetTypeMenuOptions}
          label='Asset type'
          selectProps={dropDownSelectProps}
        />
      </DialogContent>
      <DialogActions sx={dialogActionsStyle}>
        <CommonButton
          variant='contained'
          disabled={!address || !assetType}
          onClick={handleSubmit}
        >
          Cancel
        </CommonButton>
        <CommonButton
          variant='contained'
          color='secondary'
          disabled={!address || !assetType}
          onClick={handleSubmit}
        >
          Add
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AddDialog)
