import * as React from 'react'
import { Box, Card, CardContent, CardHeader, CardActions } from '@mui/material'
import DropDown from 'UI/DropDown'
import AddressSearch from 'components/AddressSearch'
import CommonButton from 'UI/CommonButton'
import useAlertEffect from 'hooks/useAlertEffect'
import { useGetAssetTypesQuery } from 'services'
import type { TPlace } from 'types'
import { Paths } from 'router/paths'
import { useNavigate } from 'react-router-dom'
import { useGoogleMapsService } from 'contexts/GoogleMapsService'
import type { SelectChangeEvent } from '@mui/material/Select'
import { useGetFilterConfigQuery } from '../../services'

const useInitialFilters = () => {
  const { data } = useGetFilterConfigQuery()

  const nuts3 = React.useMemo(
    () => data?.filter(({ filterName }) => filterName === 'nuts3_regions')?.[0],
    [data]
  )

  if (nuts3) {
    return [
      `section=${nuts3.filterName}`,
      ...nuts3.children.value.map(
        ([filterName]) =>
          `filter-${nuts3.filterName}=${encodeURIComponent(filterName)}`
      ),
    ].join('&')
  }
  return ''
}

const AddressSelector = () => {
  const initialFilters = useInitialFilters()

  const navigate = useNavigate()
  const { getGeocode } = useGoogleMapsService()
  const [address, setAddress] = React.useState<TPlace>()
  const { data, error } = useGetAssetTypesQuery()
  useAlertEffect({ error })

  const [assetType, setAssetType] = React.useState<string>('')

  const assetTypeMenuOptions = React.useMemo(
    () => [
      ...(data || []).map((value) => ({ value, label: value })),
      { value: '', label: 'Nothing selected' },
    ],
    [data]
  )

  const handleSubmit = React.useCallback(() => {
    if (getGeocode && address && assetType) {
      getGeocode(address.place_id).then(([geo]: any) => {
        const lat = geo.geometry.location.lat()
        const long = geo.geometry.location.lng()
        navigate(
          `${Paths.dataView}/${lat};${long};${encodeURIComponent(
            geo.formatted_address
          )};${assetType}?${initialFilters}`
        )
      })
    }
  }, [navigate, address, assetType, getGeocode])

  return (
    <Box
      height='100vh'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Card sx={{ width: 400 }}>
        <CardHeader
          titleTypographyProps={{ fontWeight: 700 }}
          title='Select address'
          subheader='Select address and the corresponding asset type below'
        />
        <CardContent>
          <Box paddingBottom={2}>
            <AddressSearch value={address} setValue={setAddress} />
          </Box>
          <DropDown
            options={assetTypeMenuOptions}
            label='Asset type'
            selectProps={{
              value: assetType,
              onChange: (e: SelectChangeEvent<unknown>) =>
                setAssetType(e.target.value as string),
            }}
          />
        </CardContent>
        <CardActions>
          <CommonButton
            variant='contained'
            disabled={!address || !assetType}
            onClick={handleSubmit}
          >
            Select
          </CommonButton>
        </CardActions>
      </Card>
    </Box>
  )
}

export default React.memo(AddressSelector)
