import * as React from 'react'
import { resetFilters } from '../../reducers/filter'
import { useDispatch } from 'react-redux'

const useSyncSearchParams = () => {
  const dispatch = useDispatch()

  const syncSearchParamsWithState = React.useCallback(() => {
    const { searchParams } = new URL(document.location.href)

    const filters: Record<string, Record<string, boolean>> = {}
    const sections: Record<string, boolean> = {}
    searchParams.forEach((value, key) => {
      if (key.slice(0, 6) === 'filter') {
        const sectionName = key.slice(7)
        filters[sectionName] = {
          ...filters[sectionName],
          [value]: true,
        }
      }
      if (key === 'section') {
        sections[value] = true
      }
    })
    dispatch(resetFilters({ filters, sections }))
  }, [dispatch])

  return syncSearchParamsWithState
}

export default useSyncSearchParams
