import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { PortfolioDescription } from 'typescript-types'
import { omit } from 'ramda'

type Address = {
  id: string
  lat: string
  long: string
  address: string
  assetType: string
}

type AssetViewState = {
  portfolio: Record<string, PortfolioDescription & { color: string }>
  subportfolio: Record<string, PortfolioDescription & { color: string }>
  address: Record<string, Address>
}

const initialState: AssetViewState = {
  portfolio: {},
  subportfolio: {},
  address: {},
}

const assetViewSlice = createSlice({
  name: 'assetView',
  initialState,
  reducers: {
    addSubportfolio: (
      state,
      action: PayloadAction<PortfolioDescription & { color: string }>
    ) => ({
      ...state,
      subportfolio: {
        ...state.subportfolio,
        [action.payload.id]: action.payload,
      },
    }),
    addPortfolio: (
      state,
      action: PayloadAction<PortfolioDescription & { color: string }>
    ) => ({
      ...state,
      portfolio: {
        ...state.portfolio,
        [action.payload.id]: action.payload,
      },
    }),
    addAddress: (state, action: PayloadAction<Address>) => ({
      ...state,
      address: {
        ...state.address,
        [action.payload.id]: action.payload,
      },
    }),
    deletePortfolio: (state, action: PayloadAction<string>) => ({
      ...state,
      portfolio: omit([action.payload], state.portfolio),
    }),
    deleteSubportfolio: (state, action: PayloadAction<string>) => ({
      ...state,
      subportfolio: omit([action.payload], state.subportfolio),
    }),
    deleteAddress: (state, action: PayloadAction<string>) => ({
      ...state,
      address: omit([action.payload], state.address),
    }),
  },
})

export const {
  addSubportfolio,
  addPortfolio,
  addAddress,
  deletePortfolio,
  deleteAddress,
  deleteSubportfolio,
} = assetViewSlice.actions

export default assetViewSlice.reducer
