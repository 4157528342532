import * as React from 'react'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import ColorNumber from 'UI/ColorNumber'
import useDifferenceType from 'hooks/useDifferenceType'
import { pathOr, equals } from 'ramda'
import { TableCell } from '@mui/material'
import useFocusAddress from 'hooks/useFocusAddress'

type DataCellProps = {
  selectPath: string[]
  sectionName: string
  filterName: string
}

const tableCellStyle = {
  padding: 1,
}

const DataCell: React.FC<DataCellProps> = ({
  selectPath,
  sectionName,
  filterName,
}) => {
  const focus = useFocusAddress()
  const asset = useSelector((state: RootState) => {
    return (
      (pathOr(undefined, selectPath, state) as number | undefined)?.toFixed(
        1
      ) || '-'
    )
  }, equals)

  const focusAsset = useSelector(
    (state: RootState) =>
      state.assetData.address?.[`${focus?.lat};${focus?.long}`]?.[
        sectionName
      ]?.[filterName],
    equals
  )

  const attentType = useDifferenceType(focusAsset, asset)

  const differenceText = React.useMemo(() => {
    const focusAssetScore = Number(focusAsset)
    const assetScore = Number(asset)

    if (
      Math.abs(focusAssetScore - assetScore) < 1 ||
      !focusAssetScore ||
      !assetScore
    ) {
      return undefined
    }
    if (focusAssetScore > assetScore) {
      return `-${Math.abs(focusAssetScore - assetScore).toFixed(1)}`
    }

    return `+${Math.abs(focusAssetScore - assetScore).toFixed(1)}`
  }, [asset, focusAsset])

  return (
    <TableCell align='left' sx={tableCellStyle}>
      <ColorNumber
        differenceText={differenceText}
        value={String(asset || '')}
        variant={attentType}
      />
    </TableCell>
  )
}

export default React.memo(DataCell)
