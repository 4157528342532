import * as React from 'react'
import { useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import useSyncSearchParams from '../../../hooks/useSyncSearchParams'
import type { RootState } from '../../../store'
import type { TParentCheckbox } from './types'

const stopPropagation = (e: any) => e.stopPropagation()

const ParentCheckbox: React.FC<TParentCheckbox> = ({
  name,
  dataChildren,
  ...props
}) => {
  const value = useSelector((state: RootState) => !!state.filter.sections[name])
  const syncSearchParamsWithState = useSyncSearchParams()

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { searchParams } = new URL(document.location.href)
      if (e.target.checked) {
        searchParams.append('section', name)
        dataChildren.forEach(([, { filterName }]) => {
          searchParams.append(`filter-${name}`, filterName)
        })

        window.history.replaceState(
          {},
          '',
          `${document.location.pathname}?${searchParams}`
        )
      } else {
        const filterMap: Record<string, boolean> = {}

        dataChildren.forEach(([, { filterName }]) => {
          filterMap[filterName] = true
        })

        const newSearchParams = new URLSearchParams()
        searchParams.forEach((searchParamValue, key) => {
          if (
            searchParamValue !== name &&
            !(key === `filter-${name}` && filterMap[searchParamValue])
          ) {
            newSearchParams.append(key, searchParamValue)
          }
        })
        window.history.replaceState(
          {},
          '',
          `${document.location.pathname}?${newSearchParams}`
        )
      }

      syncSearchParamsWithState()
    },
    [name, dataChildren, syncSearchParamsWithState]
  )

  return (
    <Checkbox
      checked={value}
      onChange={onChange}
      onClick={stopPropagation}
      size='small'
      {...props}
    />
  )
}

export default React.memo(ParentCheckbox)
