import * as React from 'react'
import { Box } from '@mui/material'
import { Paper } from '@mui/material'
import { RootState } from 'store'
import useOpenAndClose from 'hooks/useOpenAndClose'
import { useGetFilterConfigQuery } from 'services'
import TableSectionHeader from 'UI/TableSectionHeader'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHeader from 'UI/TableHeader'
import useHeaderTitles from 'hooks/useHeaderTitles'
import useOrderedColumns from 'hooks/useOrderedColumns'
import DeleteAddressDialog from './DeleteAddressDialog'
import DeleteFocusDialog from './DeleteFocusDialog'
import DeletePortfolioDialog from './DeletePortfolioDialog'
import DeleteSubportfolioDialog from './DeleteSubportfolioDialog'
import DataRow from './DataRow'
import NumberOfAssetsRow from './NumberOfAssetsRow'
import type { DataDescription } from 'types'
import { useSelector } from 'react-redux'
import type { FC } from 'react'
import ScoreRow from './ScoreRow'
import type { Column } from 'hooks/useOrderedColumns'

export type DataTableProps = {
  config: DataDescription
  columns: Column[]
}

const showScore = (sectionName: string) => sectionName === 'nuts3_regions'

const DataTable: FC<DataTableProps> = React.memo(({ config, columns }) => {
  const children = useSelector((state: RootState) =>
    config.children.value.filter(
      ([, { filterName }]) =>
        !!state.filter.filters?.[config.filterName]?.[filterName]
    )
  )

  return (
    <TableBody>
      {showScore(config.filterName) && (
        <>
          <ScoreRow columns={columns} />
          <NumberOfAssetsRow columns={columns} />
        </>
      )}
      <TableSectionHeader
        title={config.config.displayName}
        numberOfColumns={columns.length}
      />
      {children.map(
        (
          [
            ,
            {
              config: { displayName },
              filterName,
              children: smallChildren,
            },
          ],
          idx
        ) => (
          <DataRow
            expandable
            key={`${displayName}-${idx}`}
            sectionName={config.filterName}
            filterName={filterName}
            displayName={displayName}
            columns={columns}
            children={smallChildren}
          />
        )
      )}
    </TableBody>
  )
})

const DataView: FC<{ drawerOpen: boolean }> = ({ drawerOpen }) => {
  const columns = useOrderedColumns()
  const [
    isDeleteFocusDialogOpen,
    openDeleteFocusDialog,
    closeDeleteFocusDialog,
  ] = useOpenAndClose()
  const [addressToDelete, setAddressToDelete] =
    React.useState<{ id: string; lat: string; long: string; address: string }>()
  const closeDeleteAddressDialog = React.useCallback(
    () => setAddressToDelete(undefined),
    []
  )
  const openDeleteAddressDialog = React.useCallback(
    (id: string, lat: string, long: string, address) =>
      setAddressToDelete({ id, lat, long, address }),
    []
  )
  const [portfolioToDelete, setPortfolioToDelete] =
    React.useState<{ portfolioName: string; portfolioId: string }>()
  const closeDeletePortfolioDialog = React.useCallback(
    () => setPortfolioToDelete(undefined),
    []
  )
  const openDeletePortfolioDialog = React.useCallback(
    (portfolioId: string, portfolioName) =>
      setPortfolioToDelete({ portfolioId, portfolioName }),
    []
  )
  const [subportfolioToDelete, setSubportfolioToDelete] =
    React.useState<{ subportfolioName: string; subportfolioId: string }>()
  const closeDeleteSubPortfolioDialog = React.useCallback(
    () => setSubportfolioToDelete(undefined),
    []
  )
  const openDeleteSubportfolioDialog = React.useCallback(
    (subportfolioId: string, subportfolioName) =>
      setSubportfolioToDelete({ subportfolioId, subportfolioName }),
    []
  )

  const headerTitles = useHeaderTitles(columns, {
    openDeleteFocusDialog,
    openDeleteAddressDialog,
    openDeletePortfolioDialog,
    openDeleteSubportfolioDialog,
  })
  const { data } = useGetFilterConfigQuery()

  const tableMaxWidth = React.useMemo(
    () => (drawerOpen ? 'calc(100vw - 384px)' : 'calc(100vw - 64px)'),
    [drawerOpen]
  )

  const tableContainerStyle = React.useMemo(
    () => ({
      maxWidth: tableMaxWidth,
      overflow: 'scroll',
      width: 'fit-content',
      maxHeight: 'calc(100vh - 82px)',
      msOverflowStyle: 'none !important',
      scrollbarWidth: 'none !important',
      '-webkit-scrollbar': {
        display: 'none',
      },
    }),
    [tableMaxWidth]
  )

  return (
    <>
      <Box display='flex' flexGrow={1} flex={1} padding={1}>
        <TableContainer sx={tableContainerStyle} component={Paper}>
          <Table stickyHeader>
            <TableHeader columnTitles={headerTitles} />
            {(data || []).map((config) => (
              <DataTable
                columns={columns}
                key={config.filterName}
                config={config}
              />
            ))}
          </Table>
        </TableContainer>
      </Box>
      <DeleteFocusDialog
        open={isDeleteFocusDialogOpen}
        onClose={closeDeleteFocusDialog}
      />
      <DeleteAddressDialog
        open={Boolean(addressToDelete)}
        onClose={closeDeleteAddressDialog}
        {...addressToDelete}
      />
      <DeletePortfolioDialog
        open={Boolean(portfolioToDelete)}
        onClose={closeDeletePortfolioDialog}
        {...portfolioToDelete}
      />
      <DeleteSubportfolioDialog
        open={Boolean(subportfolioToDelete)}
        onClose={closeDeleteSubPortfolioDialog}
        {...subportfolioToDelete}
      />
    </>
  )
}

export default React.memo(DataView)
