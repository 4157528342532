import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material'
import CommonButton from 'UI/CommonButton'

const dialogContentStyle = { minWidth: 300, maxWidth: 300, padddingTop: 8 }
const dialogTitleStyle = { fontWeight: 'bold' }

type DeleteFocusDialogProps = {
  onClose: () => void
  open: boolean
}

const DeleteFocusDialog: React.FC<DeleteFocusDialogProps> = ({
  onClose,
  open,
}) => {
  const navigate = useNavigate()
  const deleteFocus = React.useCallback(() => {
    navigate('select_address')
  }, [navigate])

  return (
    <Dialog maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle sx={dialogTitleStyle}>Delete focused asset</DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <Typography>
          Delete the focused asset and go back to selecting.
        </Typography>
      </DialogContent>
      <DialogActions>
        <CommonButton variant='contained' onClick={onClose}>
          Cancel
        </CommonButton>
        <CommonButton
          onClick={deleteFocus}
          variant='contained'
          color='secondary'
        >
          Delete
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteFocusDialog
