import useFocusAddress from 'hooks/useFocusAddress'
import useLatlongs from 'hooks/useLatlongs'
import useSubportfolios from 'hooks/useSubportfolios'
import usePortfolios from 'hooks/usePortfolios'
import useCreateMapFromKey from 'hooks/useCreateMapFromKey'
import * as React from 'react'

export enum ColumnVariant {
  Focus = 'Focus',
  Address = 'Address',
  Portfolio = 'Portfolio',
  Subportfolio = 'Subportfolio',
}

export type Column = {
  data: any
  variant: ColumnVariant
}

const useOrderedColumns = (): Column[] => {
  const focus = useFocusAddress()
  const addresses = useLatlongs()
  const portfolios = usePortfolios()
  const subportfolios = useSubportfolios()
  const subportfolioMap = useCreateMapFromKey(subportfolios, 'parentFundId')
  const portfolioMap = useCreateMapFromKey(portfolios, 'id')

  return React.useMemo(() => {
    let columns = []

    if (focus) {
      columns.push({ data: focus, variant: ColumnVariant.Focus })
    }

    if (addresses) {
      const addressesWithType = addresses.map((data) => ({
        data,
        variant: ColumnVariant.Address,
      }))
      columns = [...columns, ...addressesWithType]
    }

    portfolios.forEach((portfolio) => {
      columns.push({ data: portfolio, variant: ColumnVariant.Portfolio })
      const subportfoliosForPortfolio =
        subportfolioMap[String(portfolio.id)] || []
      subportfoliosForPortfolio.forEach((subportfolio) => {
        columns.push({
          data: subportfolio,
          variant: ColumnVariant.Subportfolio,
        })
      })
    })

    // some subportfolios might not have a portfolio, they have to be added too
    subportfolios.forEach((subportfolio) => {
      if (!portfolioMap[String(subportfolio.parentFundId)]) {
        columns.push({
          data: subportfolio,
          variant: ColumnVariant.Subportfolio,
        })
      }
    })

    return columns
  }, [
    focus,
    addresses,
    portfolioMap,
    subportfolioMap,
    subportfolios,
    portfolios,
  ])
}

export default useOrderedColumns
