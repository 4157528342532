import { Box, Card, Typography } from '@mui/material'
import { login } from 'utils/auth'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CommonButton from 'UI/CommonButton'
import CommonTextField from 'UI/CommonTextField'
import React from 'react'
import type { FC } from 'react'

const LoginView: FC = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = React.useMemo(
    () =>
      handleSubmit(({ email, password }) => {
        login(email, password).then(() => navigate('/select_address'))
      }),
    [handleSubmit, navigate]
  )

  return (
    <Box
      width='100vw'
      height='100vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Card>
        <Box display='flex' flexDirection='column' maxWidth={300}>
          <form onSubmit={onSubmit} autoComplete='off'>
            <Box margin={2} padding={1} borderRadius={3}>
              <Typography sx={{ padding: 1 }} fontWeight='bold' variant='h6'>
                Login
              </Typography>
              <CommonTextField
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : undefined}
                label='Username'
                variant='filled'
                {...register('email', { required: 'Username is required' })}
              />
              <CommonTextField
                error={!!errors.password}
                helperText={errors.email ? errors.password.message : undefined}
                type='password'
                label='Password'
                variant='filled'
                {...register('password', { required: 'Password is required' })}
              />
              <CommonButton
                variant='contained'
                color='secondary'
                autoFocus
                fullWidth
                type='submit'
              >
                Login
              </CommonButton>
            </Box>
          </form>
        </Box>
      </Card>
    </Box>
  )
}

export default React.memo(LoginView)
