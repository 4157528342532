import React from 'react'
import {
  normalExpectation,
  normalStandardDeviation,
  normalPdf,
} from 'utils/math'
import { sort } from 'ramda'
import { range } from 'lodash'
import * as d3 from 'd3'
import { Tooltip } from '@mui/material'
import { TABLE_CELL_WIDTH } from '../../constants'

const NUMBERS = range(0, 8, 0.1)

const BarChart: React.FC<{ data: number[]; assetPoint: number | null }> = ({
  data,
  assetPoint,
}) => {
  const ref = React.useRef<SVGSVGElement>(null)
  const mean = React.useMemo(() => normalExpectation(data), [data])
  const std = React.useMemo(
    () => normalStandardDeviation(data, mean),
    [data, mean]
  )

  React.useEffect(() => {
    const lineData: [number, number][] = sort(
      (a, b) => a - b,
      [...NUMBERS]
    ).map((x: number) => [x, 100 * normalPdf(mean, std, x)])

    var xScale = d3.scaleLinear().domain([0, 8]).range([0, TABLE_CELL_WIDTH])
    var yScale = d3
      .scaleLinear()
      .domain([0, mean * 8])
      .range([100, 80])

    var line = d3
      .line()
      .x((d) => xScale(d[0]))
      .y((d) => yScale(d[1]))
      .curve(d3.curveNatural)

    d3.select(ref.current)
      .append('path')
      .attr('d', line(lineData))
      .attr('fill', 'none')
      .attr('stroke', '#cb5e7b')
      .attr('stroke-width', 2.5)

    data.forEach((x) => {
      d3.select(ref.current)
        .append('circle')
        .style('stroke', 'black')
        .style('fill', 'white')
        .attr('r', 4)
        .attr('cx', xScale(x))
        .attr('cy', TABLE_CELL_WIDTH - 4)
        .attr('shape-rendering', 'auto')
    })

    if (assetPoint) {
      d3.select(ref.current)
        .append('circle')
        .style('stroke', 'black')
        .style('fill', '#66FF66')
        .attr('r', 4)
        .attr('cx', xScale(assetPoint))
        .attr('cy', TABLE_CELL_WIDTH - 4)
        .attr('shape-rendering', 'auto')
    }
  }, [data, assetPoint, mean, std])

  const tooltipTitle = React.useMemo(
    () =>
      mean && std
        ? `Normal distribution with mean: ${mean.toFixed(
            2
          )} and standard deviation ${std.toFixed(2)}`
        : '',
    [mean, std]
  )

  return (
    <Tooltip title={tooltipTitle}>
      <svg
        ref={ref}
        style={{
          marginRight: '0px',
          marginLeft: '0px',
        }}
        viewBox={`0 0 ${TABLE_CELL_WIDTH} ${TABLE_CELL_WIDTH}`}
      />
    </Tooltip>
  )
}

export default BarChart
