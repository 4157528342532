import * as React from 'react'
import { useGetSubportfolioAssetScoresByFilterQuery } from 'services'
import type { PortfolioDataCellProps } from '../PortfolioTypeDataCell'
import PortfolioTypeDataCell from '../PortfolioTypeDataCell'

const SubportfolioCell: React.FC<Omit<PortfolioDataCellProps, 'data'>> = ({
  id,
  filterName,
  sectionName,
  expanded,
  setExpanded,
  expandable,
}) => {
  const { data } = useGetSubportfolioAssetScoresByFilterQuery(
    {
      id,
      filter: filterName,
      section: sectionName,
    },
    {
      skip: !expanded,
    }
  )

  return (
    <PortfolioTypeDataCell
      id={id}
      filterName={filterName}
      sectionName={sectionName}
      data={data}
      expandable={expandable}
      expanded={expanded}
      setExpanded={setExpanded}
      subportfolioMode
    />
  )
}

export default SubportfolioCell
