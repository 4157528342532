import { useParams } from 'react-router-dom'
import { decodeAddress } from 'utils/url'

const useFocusAddress = () => {
  const params = useParams()

  return params.focus ? decodeAddress(params.focus) : undefined
}

export default useFocusAddress
