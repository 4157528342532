import { ColumnVariant } from 'hooks/useOrderedColumns'
import DataCell from '../DataCell'
import PortfolioDataCell from '../PortfolioCell'
import SubportfolioDataCell from '../SubportfolioCell'
import * as React from 'react'

type DataRowItemProps = {
  variant: ColumnVariant
  data: any
  sectionName: string
  filterName: string
  expanded: boolean
  expandable: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

const DataRowItem: React.FC<DataRowItemProps> = ({
  variant,
  data,
  sectionName,
  filterName,
  setExpanded,
  expanded,
  expandable,
}) => {
  const selectPath = React.useMemo(
    () => [
      'assetData',
      'address',
      `${data.lat};${data.long}`,
      sectionName,
      filterName,
    ],
    [sectionName, filterName, data.lat, data.long]
  )

  switch (variant) {
    case ColumnVariant.Focus:
      return (
        <DataCell
          selectPath={selectPath}
          sectionName={sectionName}
          filterName={filterName}
        />
      )
    case ColumnVariant.Address:
      return (
        <DataCell
          selectPath={selectPath}
          sectionName={sectionName}
          filterName={filterName}
        />
      )
    case ColumnVariant.Portfolio:
      return (
        <PortfolioDataCell
          id={data.id}
          sectionName={sectionName}
          filterName={filterName}
          expandable={expandable}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      )
    case ColumnVariant.Subportfolio:
      return (
        <SubportfolioDataCell
          id={data.id}
          sectionName={sectionName}
          filterName={filterName}
          expandable={expandable}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      )
  }
}

export default DataRowItem
