import { Drawer, Box, IconButton, Tooltip } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'

type DrawerProps = {
  width: number
  open: () => void
  close: () => void
  isOpen: boolean
}

const CommonDrawer: React.FC<DrawerProps> = ({
  width,
  children,
  isOpen,
  open,
  close,
}) => (
  <>
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          boxSizing: 'border-box',
        },
      }}
      variant='persistent'
      anchor='left'
      open={isOpen}
    >
      <Box
        paddingTop={0.5}
        paddingBottom={0.5}
        display='flex'
        flexDirection='row-reverse'
        borderBottom='1px solid rgba(0, 0, 0, 0.12)'
      >
        <IconButton size='small' onClick={close}>
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      {children}
    </Drawer>
    {!isOpen && (
      <Box position='absolute'>
        <Tooltip title='Open filter menu'>
          <IconButton size='small' onClick={open}>
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>
      </Box>
    )}
  </>
)

export default CommonDrawer
