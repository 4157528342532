import * as React from 'react'
import { useDispatch } from 'react-redux'
import TableRow from '@mui/material/TableRow'
import TextCell from 'UI/TextCell'
import ClusterDialog from '../ClusterDialog'
import { addClusterKeys } from 'reducers/clusterView'
import DataRowItem from '../DataRowItem'
import type { DataDescription } from 'types'
import type { Column } from 'hooks/useOrderedColumns'

const DataRow: React.FC<{
  displayName: string
  filterName: string
  sectionName: string
  columns: Column[]
  children?: DataDescription['children']
  expandable?: boolean
}> = ({
  expandable,
  displayName,
  columns,
  sectionName,
  filterName,
  children,
}) => {
  const dispatch = useDispatch()
  const [clusterDialogState, setClusterDialogState] = React.useState<{
    filterNames: string[]
    sectionName: string
    displayName: string
  }>()
  const closeClusterDialog = React.useCallback(
    () => setClusterDialogState(undefined),
    []
  )
  const [expanded, setExpanded] = React.useState(false)

  const openCluster = React.useMemo(() => {
    if ((children?.value?.length || 0) > 0) {
      return () => {
        const filterNames = (children?.value || []).map(
          ([, { filterName: childFilterName }]) => childFilterName
        )
        dispatch(addClusterKeys({ sectionName, filterNames }))
        setClusterDialogState({ filterNames, sectionName, displayName })
      }
    }
    return undefined
  }, [children, sectionName, dispatch, displayName])

  return (
    <>
      <ClusterDialog
        columns={columns}
        open={Boolean(clusterDialogState)}
        onClose={closeClusterDialog}
        {...clusterDialogState}
      />
      <TableRow>
        <TextCell
          onClick={openCluster}
          text={`${displayName}${Boolean(openCluster) ? '*' : ''}`}
        />
        {columns.map(({ data, variant }, idx) => (
          <DataRowItem
            expandable={Boolean(expandable)}
            key={idx}
            data={data}
            variant={variant}
            sectionName={sectionName}
            filterName={filterName}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ))}
      </TableRow>
    </>
  )
}

export default React.memo(DataRow)
