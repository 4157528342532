import { AttentionType } from 'UI/ColorNumber'
import * as React from 'react'

const useDifferenceType = (
  a: number | string | null,
  b: number | string | null
) =>
  React.useMemo(() => {
    const castedA = Number(a)
    const castedB = Number(b)

    if (Math.abs(castedA - castedB) < 1 || !castedA || !castedB) {
      return AttentionType.Similar
    }
    if (castedA > castedB) {
      return AttentionType.Less
    }

    return AttentionType.More
  }, [a, b])

export default useDifferenceType
