import * as React from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import AddAddressDialog from './AddAddressDialog'
import AddPortfolioDialog from './AddPortfolioDialog'
import AddSpeedDial from './AddSpeedDial'
import AssetData from './AssetData'
import AssetDataService from 'contexts/AssetDataService'
import Filter from 'components/Filter'
import DirectionsIcon from '@mui/icons-material/Directions'
import TableRowsIcon from '@mui/icons-material/TableRows'
import useOpenAndClose from 'hooks/useOpenAndClose'
import Main from 'UI/Main'
import { DRAWER_WIDTH } from '../../constants'
import Drawer from 'UI/Drawer'
import useSyncSearchParamsEffect from 'hooks/useSyncSearchParamsOnPathChange'
import Map from './Map'

const Home = () => {
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue)
    },
    [setTabValue]
  )

  const [drawerOpen, openDrawer, closeDrawer] = useOpenAndClose(true)
  useSyncSearchParamsEffect()
  const [addAddressDialogOpen, openAddAddressDialog, closeAddAddressDialog] =
    useOpenAndClose()

  const [
    addPortfolioDialogOpen,
    openAddPortfolioDialog,
    closeAddPortfolioDialog,
  ] = useOpenAndClose()

  return (
    <AssetDataService>
      <Box display='flex'>
        <Drawer
          width={DRAWER_WIDTH}
          isOpen={drawerOpen}
          open={openDrawer}
          close={closeDrawer}
        >
          <Tabs
            indicatorColor='secondary'
            textColor='secondary'
            variant='fullWidth'
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <Tab
              icon={<TableRowsIcon fontSize='small' />}
              iconPosition='end'
              color='secondary'
              label='Table'
            />
            <Tab
              icon={<DirectionsIcon fontSize='small' />}
              iconPosition='end'
              color='secondary'
              label='Map'
            />
          </Tabs>
          <Filter />
        </Drawer>
        <Main open={drawerOpen}>
          {tabValue === 0 ? <AssetData drawerOpen={drawerOpen} /> : <Map />}
        </Main>
      </Box>
      <AddPortfolioDialog
        open={addPortfolioDialogOpen}
        onClose={closeAddPortfolioDialog}
      />
      <AddAddressDialog
        open={addAddressDialogOpen}
        onClose={closeAddAddressDialog}
      />
      <AddSpeedDial
        openAddAddressDialog={openAddAddressDialog}
        openAddPortfolioDialog={openAddPortfolioDialog}
      />
    </AssetDataService>
  )
}

export default React.memo(Home)
