import * as React from 'react'
import useFocusAddress from 'hooks/useFocusAddress'
import { Box } from '@mui/material'
import { RootState } from 'store'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import NormalDist from 'components/NormalDist'
import { Accordion } from '@mui/material'
import { uniq } from 'ramda'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useDifferenceType from 'hooks/useDifferenceType'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { AccordionDetails } from '@mui/material'
import ColorNumber from 'UI/ColorNumber'
import { TABLE_CELL_WIDTH } from '../../../../constants'

const getNotApplicableMessage = (reason: string) =>
  `Not applicable for this portfolio (${reason})`

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(() => ({
  margin: 0,
  padding: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    flexDirection: 'row',
    display: 'flex',
    margin: 0,
  },
}))

export type PortfolioDataCellProps = {
  id: string
  filterName: string
  sectionName: string
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  expandable: boolean
  expanded: boolean
  data: Record<string, number> | undefined
  subportfolioMode?: boolean
}

const accordionStyle = {
  padding: 0,
  minHeight: 0,
  '&:before': {
    display: 'none',
  },
}
const transitionProps = { timeout: 150, unmountOnExit: true }
const tableCellStyle = { padding: 1 }
const accordionDetailsStyle = {
  padding: 0,
  paddingTop: 1,
}

const PortfolioTypeDataCell: React.FC<PortfolioDataCellProps> = ({
  id,
  filterName,
  sectionName,
  expandable,
  expanded,
  setExpanded,
  subportfolioMode,
  data,
}) => {
  const focus = useFocusAddress()
  const [notApplicableMessage, setNotApplicableMessage] = React.useState('')

  const portfolioKey = React.useMemo(
    () => (subportfolioMode ? 'subportfolio' : 'portfolio'),
    [subportfolioMode]
  )
  const asset = useSelector(
    (state: RootState) =>
      state.assetData[portfolioKey]?.[id]?.[sectionName]?.[filterName]?.toFixed(
        1
      ) || '-'
  )
  const focusAsset = useSelector(
    (state: RootState) =>
      state.assetData.address?.[`${focus?.lat};${focus?.long}`]?.[
        sectionName
      ]?.[filterName]
  )

  const attentType = useDifferenceType(focusAsset, asset)

  const differenceText = React.useMemo(() => {
    const focusAssetScore = Number(focusAsset)
    const assetScore = Number(asset)

    if (
      Math.abs(focusAssetScore - assetScore) < 1 ||
      !focusAssetScore ||
      !assetScore
    ) {
      return undefined
    }
    if (focusAssetScore > assetScore) {
      return `-${Math.abs(focusAssetScore - assetScore).toFixed(1)}`
    }

    return `+${Math.abs(focusAssetScore - assetScore).toFixed(1)}`
  }, [asset, focusAsset])

  const dataArray = React.useMemo(
    () => (data ? Object.values(data) : []),
    [data]
  )

  React.useEffect(() => {
    if (dataArray.every((x) => x === null)) {
      return setNotApplicableMessage('Not available')
    }
    if (uniq(dataArray).length === 1) {
      return setNotApplicableMessage(
        getNotApplicableMessage('each asset has the same value')
      )
    }
    return setNotApplicableMessage('')
  }, [dataArray])

  const changeExpanded = React.useCallback(
    (_, newExpanded) => setExpanded(newExpanded),
    [setExpanded]
  )

  return (
    <TableCell sx={tableCellStyle}>
      {expandable ? (
        <Accordion
          expanded={expanded}
          onChange={changeExpanded}
          TransitionProps={transitionProps}
          elevation={0}
          disableGutters
          sx={accordionStyle}
        >
          <CustomAccordionSummary>
            <Box>
              {asset && (
                <ColorNumber
                  differenceText={differenceText}
                  value={String(asset || '')}
                  variant={attentType}
                />
              )}
            </Box>
          </CustomAccordionSummary>
          <AccordionDetails sx={accordionDetailsStyle}>
            {data && (
              <Box height={TABLE_CELL_WIDTH} width={TABLE_CELL_WIDTH}>
                {notApplicableMessage ? (
                  <Typography variant='body2'>
                    {notApplicableMessage}
                  </Typography>
                ) : (
                  <NormalDist data={dataArray} assetPoint={focusAsset} />
                )}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box display='flex'>
          {asset && (
            <ColorNumber
              differenceText={differenceText}
              value={String(asset || '')}
              variant={attentType}
            />
          )}
        </Box>
      )}
    </TableCell>
  )
}

export default React.memo(PortfolioTypeDataCell)
