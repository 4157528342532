import { API_BASE_URL, fetchJson } from 'utils/network'
import { AppThunk } from 'store'
import { addData } from 'reducers/assetData'
import { getToken } from 'utils/auth'

const fetchPortfolioData =
  (
    id: string,
    sectionName: string,
    newFilters: string[]
  ): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      if (newFilters.length === 0) {
        return true
      }

      const filterQuery = newFilters
        .map((filter) => {
          return `key=${encodeURIComponent(filter)}`
        })
        .join('&')
      const tk = getToken()

      const url = `${API_BASE_URL}/data/subportfolios/${id}/${sectionName}?${filterQuery}&tk=${tk}`
      const response = await fetchJson(url)

      dispatch(
        addData({
          data: response as any,
          id,
          sectionName,
          assetType: 'subportfolio',
        })
      )
      return true
    } catch (e) {
      console.error('e', e)
      return false
    }
  }

export default fetchPortfolioData
