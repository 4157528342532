import * as React from 'react'
import {
  useGetAddressAssetScoreQuery,
  useGetGeoInfoQuery,
  useGetPortfolioAssetScoreQuery,
  useGetSubportfolioAssetScoreQuery,
} from 'services'
import TableRow from '@mui/material/TableRow'
import SmallHeaderCell from 'UI/SmallHeaderCell'
import TextCell from 'UI/TextCell'
import type { DataTableProps } from '..'
import { ColumnVariant } from 'hooks/useOrderedColumns'

const AddressScoreCell: React.FC<{ assetType: string; geocode?: string }> = ({
  assetType,
  geocode,
}) => {
  const { data } = useGetAddressAssetScoreQuery(geocode as string, {
    skip: !geocode,
  })

  return <TextCell text={data?.[assetType]?.toFixed(1) || ''} />
}

const PortfolioScoreCell: React.FC<{ id?: string; name: string }> = ({
  id,
}) => {
  const { data } = useGetPortfolioAssetScoreQuery(id as string, { skip: !id })

  return <TextCell text={data?.['Total Score']?.toFixed(1) || ''} />
}

const SubportfolioScoreCell: React.FC<{ id?: string; name: string }> = ({
  id,
}) => {
  const { data } = useGetSubportfolioAssetScoreQuery(id as string, {
    skip: !id,
  })

  return <TextCell text={data?.['Total Score']?.toFixed(1) || ''} />
}

const ScoreRow: React.FC<Pick<DataTableProps, 'columns'>> = ({ columns }) => {
  const addresses = React.useMemo(() => {
    return columns
      .filter(
        ({ variant }) =>
          variant === ColumnVariant.Focus || variant === ColumnVariant.Address
      )
      .map(({ data }) => ({ lat: data.lat, long: data.long })) as {
      lat: string
      long: string
    }[]
  }, [columns])
  const { data: geoData } = useGetGeoInfoQuery(addresses)

  return (
    <TableRow>
      <SmallHeaderCell text='NUTs3 Score' />
      {columns.map(({ data, variant }, idx) => {
        switch (variant) {
          case ColumnVariant.Focus:
            return (
              <AddressScoreCell
                key={`${data.lat};${data.long}-${idx}`}
                assetType={data.assetType}
                geocode={geoData?.[`${data.lat};${data.long}`]?.[3]?.id}
              />
            )
          case ColumnVariant.Address:
            return (
              <AddressScoreCell
                key={`${data.lat};${data.long}-${idx}`}
                assetType={data.assetType}
                geocode={geoData?.[`${data.lat};${data.long}`]?.[3]?.id}
              />
            )
          case ColumnVariant.Portfolio:
            return <PortfolioScoreCell key={`${data.id}-${idx}`} {...data} />
          case ColumnVariant.Subportfolio:
            return <SubportfolioScoreCell key={`${data.id}-${idx}`} {...data} />
        }
      })}
    </TableRow>
  )
}

export default React.memo(ScoreRow)
