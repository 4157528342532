import * as React from 'react'
import { Accordion, Box, ListItem, Typography, Tooltip } from '@mui/material'
import CommonTextField from 'UI/CommonTextField'
import CommonButton from 'UI/CommonButton'
import type { FC } from 'react'
import { DataDescription } from './types'
import useOpenAndClose from 'hooks/useOpenAndClose'
import { InputAdornment } from '@mui/material'
import { useGetFilterConfigQuery } from '../../services'
import AccordionSummary from 'UI/AccordionSummary'
import NewPresetDialog from './NewPresetDialog'
import LoadPresetDialog from './LoadPresetDialog'
import FilterListIcon from '@mui/icons-material/FilterList'
import LeafCheckbox from './LeafCheckbox'
import ParentCheckbox from './ParentCheckbox'

const getDisplayName = (config: DataDescription) => {
  return config.config.displayName
}

const Children: FC<{ configs: DataDescription[]; sectionName: string }> = ({
  configs,
  sectionName,
}) => (
  <>
    {configs.map((config) => (
      <ListItem key={getDisplayName(config)} sx={{ padding: 0 }}>
        <Box display='flex' alignItems='center'>
          <LeafCheckbox
            sectionName={sectionName}
            key={getDisplayName(config)}
            name={config.filterName}
          />

          <Typography variant='body2' sx={{ fontSize: '0.7rem' }}>
            {getDisplayName(config)}
          </Typography>
        </Box>
      </ListItem>
    ))}
  </>
)

const FilterSection = ({
  config,
  search,
}: {
  config: DataDescription
  search: string
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const children = React.useMemo(
    () =>
      config.children.value
        .map(([, child]) => child)
        .filter(({ filterName }) => filterName.toLowerCase().includes(search)),
    [config, search]
  )

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setIsOpen(isExpanded)
    },
    []
  )

  React.useEffect(() => {
    setIsOpen(true)
  }, [search])

  return (
    <Accordion
      expanded={isOpen}
      onChange={handleChange}
      TransitionProps={{ timeout: 150 }}
      elevation={0}
      disableGutters
      sx={{
        padding: 0,
        minHeight: 0,
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary>
        <Box display='flex' alignItems='center'>
          <ParentCheckbox
            name={config.filterName}
            dataChildren={config.children.value}
          />
          <Typography variant='body2' sx={{ fontSize: '0.7rem' }}>
            {getDisplayName(config)}
          </Typography>
        </Box>
      </AccordionSummary>
      <Box paddingLeft={1}>
        <Children sectionName={config.filterName} configs={children} />
      </Box>
    </Accordion>
  )
}

var Filter = () => {
  const { data } = useGetFilterConfigQuery()
  const [newPresetDialogOpen, openNewPresetDialog, closeNewPresetDialog] =
    useOpenAndClose()
  const [loadPresetDialogOpen, openLoadPresetDialog, closeLoadPresetDialog] =
    useOpenAndClose()

  const [search, setSearch] = React.useState<string>('')
  const onChangeFilterInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    []
  )

  return (
    <>
      <NewPresetDialog
        open={newPresetDialogOpen}
        onClose={closeNewPresetDialog}
      />
      <LoadPresetDialog
        open={loadPresetDialogOpen}
        onClose={closeLoadPresetDialog}
      />
      <CommonTextField
        placeholder='filter'
        value={search}
        onChange={onChangeFilterInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <FilterListIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          width: '100%',
          maxWidth: 460,
          bgcolor: 'background.paper',
          height: 'calc(100vh - 110px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {data ? (
          <form>
            {data.map((config) => (
              <FilterSection
                search={search.toLowerCase()}
                config={config}
                key={config.filterName}
              />
            ))}
          </form>
        ) : (
          <div />
        )}
      </Box>
      <Box
        display='flex'
        justifyContent='space-around'
        sx={{ backgroundColor: '#bbbbbb29', borderTop: '1px solid grey' }}
      >
        <Tooltip title='Save the current state of filters to be able to re-use it later.'>
          <div>
            <CommonButton
              color='secondary'
              variant='contained'
              onClick={openNewPresetDialog}
            >
              Save filters
            </CommonButton>
          </div>
        </Tooltip>
        <Tooltip title='Load a preset of filters.'>
          <div>
            <CommonButton
              color='secondary'
              variant='contained'
              onClick={openLoadPresetDialog}
            >
              Load filters
            </CommonButton>
          </div>
        </Tooltip>
      </Box>
    </>
  )
}

export default React.memo(Filter)
