import * as React from 'react'
import { useGoogleMapsService } from 'contexts/GoogleMapsService'
import useFocusAddress from 'hooks/useFocusAddress'
import useLatlongs from 'hooks/useLatlongs'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import {
  useGetPortfolioAssetLocationsQuery,
  useGetSubportfolioAssetLocationsQuery,
} from 'services'

const Map = () => {
  const portfolios = useSelector(
    (state: RootState) => state.assetView.portfolio
  )
  const portfolioIds = React.useMemo(
    () => Object.keys(portfolios),
    [portfolios]
  )
  const portfolioLocations = useGetPortfolioAssetLocationsQuery(portfolioIds)
  const subportfolios = useSelector(
    (state: RootState) => state.assetView.subportfolio
  )
  const subportfolioIds = React.useMemo(
    () => Object.keys(subportfolios),
    [subportfolios]
  )
  const subportfolioLocations =
    useGetSubportfolioAssetLocationsQuery(subportfolioIds)

  const addresses = useLatlongs()
  const [ref, setRef] = React.useState<HTMLDivElement>()
  const focus = useFocusAddress()
  const { initMap, addMarker } = useGoogleMapsService()

  const map = React.useMemo(() => {
    try {
      if (
        ref &&
        initMap &&
        addMarker &&
        focus?.lat !== undefined &&
        focus?.long !== undefined
      ) {
        const tempMap = initMap(ref, {
          lat: Number(focus?.lat),
          lng: Number(focus?.long),
        })
        addMarker(tempMap, 'green', {
          lat: Number(focus?.lat),
          lng: Number(focus?.long),
        })
        return tempMap
      }
    } catch (e) {
      console.error(e)
    }
  }, [ref, initMap, focus?.lat, focus?.long, addMarker])

  React.useEffect(() => {
    Object.entries(portfolioLocations).forEach(([portfolioId, locations]) => {
      const portfolio = portfolios[portfolioId]

      if (portfolio && map) {
        Object.values(locations).forEach(({ lat, long }) => {
          addMarker(map, portfolio.color, { lat, lng: long })
        })
      }
    })
  }, [portfolios, portfolioLocations, addMarker, map])

  React.useEffect(() => {
    Object.entries(subportfolioLocations).forEach(
      ([portfolioId, locations]) => {
        const subportfolio = subportfolios[portfolioId]

        if (subportfolio && map) {
          Object.values(locations).forEach(({ lat, long }) => {
            addMarker(map, subportfolio.color, { lat, lng: long })
          })
        }
      }
    )
  }, [subportfolios, subportfolioLocations, addMarker, map])

  React.useEffect(() => {
    addresses.forEach(({ lat, long }) => {
      addMarker(map, 'red', { lat: Number(lat), lng: Number(long) })
    })
  }, [map, addresses, addMarker])

  const changeRef = React.useCallback(
    (element: HTMLDivElement) => setRef(element),
    []
  )

  return (
    <div
      ref={changeRef}
      style={{ height: 'calc(100vh - 64px)', width: '100%' }}
    />
  )
}

export default React.memo(Map)
