import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { deleteAddress } from 'reducers/assetView'
import CommonButton from 'UI/CommonButton'

const dialogContentStyle = { minWidth: 300, maxWidth: 300, padddingTop: 8 }
const dialogTitleStyle = { fontWeight: 'bold' }

type DeleteAddressDialogProps = {
  onClose: () => void
  open: boolean
  id?: string
  lat?: string
  long?: string
  address?: string
}

const DeleteAddressDialog: React.FC<DeleteAddressDialogProps> = ({
  onClose,
  open,
  lat,
  id,
  long,
  address,
}) => {
  const dispatch = useDispatch()
  const commitDeleteAddress = React.useCallback(() => {
    if (id) {
      dispatch(deleteAddress(id))
      onClose()
    }
  }, [dispatch, id, onClose])

  return (
    <Dialog
      maxWidth='sm'
      open={open && Boolean(lat) && Boolean(long) && Boolean(address)}
      onClose={onClose}
    >
      <DialogTitle sx={dialogTitleStyle}>Delete address</DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <Typography>
          Delete <i>{address}</i> from the overview.
        </Typography>
      </DialogContent>
      <DialogActions>
        <CommonButton variant='contained' onClick={onClose}>
          Cancel
        </CommonButton>
        <CommonButton
          onClick={commitDeleteAddress}
          variant='contained'
          color='secondary'
        >
          Delete
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAddressDialog
