import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material'
import {
  Home as HomeIcon,
  Collections as CollectionsIcon,
  FileDownload,
} from '@mui/icons-material'
import { useExportData } from 'utils/network'
import * as React from 'react'

type AddSpeedDialProps = {
  openAddPortfolioDialog: () => void
  openAddAddressDialog: () => void
}

const speedDialStyle = { position: 'absolute', bottom: 16, right: 16 }

const AddSpeedDial: React.FC<AddSpeedDialProps> = ({
  openAddAddressDialog,
  openAddPortfolioDialog,
}) => {
  const exportData = useExportData()
  return (
    <SpeedDial
      ariaLabel='Add SpeedDial'
      sx={speedDialStyle}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction
        onClick={openAddPortfolioDialog}
        icon={<CollectionsIcon />}
        tooltipTitle='Add portfolio'
      />
      <SpeedDialAction
        onClick={openAddAddressDialog}
        icon={<HomeIcon />}
        tooltipTitle='Add address'
      />
      <SpeedDialAction
        onClick={exportData}
        icon={<FileDownload />}
        tooltipTitle='Export'
      />
    </SpeedDial>
  )
}

export default React.memo(AddSpeedDial)
