import { api } from '../services'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import assetDataReducer from '../reducers/assetData'
import filterReducer from '../reducers/filter'
import clusterViewReducer from '../reducers/clusterView'
import assetViewReducer from '../reducers/assetView'
import type { Action } from 'redux'
import type { ThunkAction } from 'redux-thunk'

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    filter: filterReducer,
    assetData: assetDataReducer,
    clusterView: clusterViewReducer,
    assetView: assetViewReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})

setupListeners(store.dispatch)

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
