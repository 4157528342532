import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import AlertProvider from 'contexts/AlertProvider'
import GoogleMapsService from './contexts/GoogleMapsService'
import Router from './router'
import store from './store'
import theme from './theme'

var App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <GoogleMapsService>
            <Router />
          </GoogleMapsService>
        </AlertProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
