import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type ClusterViewState = Record<string, Record<string, boolean>>

const initialState: ClusterViewState = {}

const clusterViewSlice = createSlice({
  name: 'clusterView',
  initialState,
  reducers: {
    addClusterKeys: (
      state,
      action: PayloadAction<{ sectionName: string; filterNames: string[] }>
    ) => ({
      ...state,
      [action.payload.sectionName]: {
        ...state[action.payload.sectionName],
        ...Object.fromEntries(
          action.payload.filterNames.map((filterName) => [filterName, true])
        ),
      },
    }),
  },
})

export const { addClusterKeys } = clusterViewSlice.actions

export default clusterViewSlice.reducer
